import React from 'react';
import logo from '../fkz_logo.png';
import Navigation from '../components/navigation';


function Header() {
  return (
    <header className="app-header uk-background-blue uk-margin-large-bottom ">
      <nav className="uk-navbar uk-navbar-container uk-navbar-transparent uk-margin-remove uk-box-shadow-hover-medium" data-uk-navbar="mode: click">
        <div className="uk-navbar-center uk-text-center">
        <a className="uk-logo" href={process.env.PUBLIC_URL}><img src={logo} data-uk-svg width="50" height="50" /></a>
        </div>
        <div className="uk-navbar-right">
          <a className="uk-navbar-toggle" data-uk-navbar-toggle-icon href="#" data-uk-toggle="target: #offcanvas-overlay"></a>
        </div>
      </nav>
      <progress id="js-progressbar" className="uk-progress" value="0" max="100"></progress>


      <Navigation />
    </header>
  );
}

export default Header;