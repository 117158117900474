import { MonriParms } from "./components/api";
import enLang from "./lang/en.json";

// Dictionary of translations
export const langQuery = MonriParms("lang");
export const listLocal = ["bs", "en"]

export function setLocal(local, refres = true) {


    var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("lang", local);
        window.history.replaceState(null, null, "?"+queryParams.toString());
   

    if(local) {
        localStorage.setItem("localLang", local)
        if(refres) { window.location.reload()}
       
    } else {
        localStorage.setItem("localLang", "bs")
        if(refres) { window.location.reload()}
    }
    

}

export function getLocal() {

    let local = localStorage.getItem("localLang");
    if(local) {
        return local;
    } else {
        return 'bs';
    }
}


export default function _t(text) {
    
    let local = localStorage.getItem("localLang");
    let language = enLang;

    if(local && local != 'bs') {
        
        local = getLocal()
        language = require("./lang/" + local +".json");
        if(language[text]) {
            return language[text];
        } else {
            return text;
        }
        

    } else {
        local = "bs"
        return text;   

    }
    
}

