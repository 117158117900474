import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route, Link, HashRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./home";
import PageVirtual from "./page-virtual";
import Finish from "./finish";
import { setLocal, listLocal, langQuery } from "./i18n";
import PageTickets from "./page-tickets";
import PageSeason from "./page-seasons";
import { getToken, readToken } from "./components/api";

const container = document.getElementById('root');

//const langQuery = MonriParms("lang");
if(getToken()) {
  //console.log("OK");
} else {
  //console.log("NOT");
}
// Create a root.
if(listLocal.includes(langQuery)) {
  setLocal(langQuery, false);
}
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Header />
    <HashRouter>
      <Routes>
          <Route path="placanje" element={<Finish />} />
          <Route path="odaberi-tribinu" element={<PageTickets />} />
          <Route path="virtuelna-ulaznica" element={<PageVirtual />} />
          <Route path="sezonska-ulaznica" element={<PageSeason />} />
          <Route exact path={"/"} element={<Home />} />
        </Routes>
    </HashRouter>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
