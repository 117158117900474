import React from "react";
import UIkit from 'uikit';
import { sha512 } from 'js-sha512';
import {fetchMonri, getToken, Loader, MonriParms, readToken} from "./components/api";
import _t, {getLocal} from "./i18n";



class Finish extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: <Loader />,
            data: null,
            status: null,
            isLoaded: false,
        }
    }

    wait(timeout) {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    }

    progressbar(max) {
        var progress = UIkit.util.ready(function () {
            var bar = document.getElementById('js-progressbar');        
                bar.value = max;
        });
    }

    startUp = async () => {

        if(MonriParms('order_number') && MonriParms('digest') && MonriParms('response_code')) {
            await getToken()

            let data = {
                'digest': MonriParms('digest'),
                'order_number': MonriParms('order_number'),
                'currency': MonriParms('currency'),
                'amount': MonriParms('amount'),
                'response_code': MonriParms('response_code'),    
                'url': btoa(window.location.href),
                'lang': getLocal()
            }

        

            if(this.state.isLoaded == false) {   
                this.newFunction(data);
                this.setState({isLoaded: true}); 

            }
     
                    

        } else {

            this.setState({text: _t("Došlo je do greške...")});

        }

    }

    newFunction = async (data) => {

        const monriData = await fetchMonri(data)

        let msgText = '';
        let msgIcon = '';
        let msgLink = '';
        let msgImg = ' <img src="finish.jpg">';
        if(monriData.status == "success") {
            msgIcon = <span uk-icon="icon: check; ratio: 2.5"></span>;
            msgText = _t("Vaša kupovina je uspješno završena. Molimo provjerite vaš email.");
            this.progressbar(100);
        } else {
            msgIcon = <span uk-icon="icon: warning; ratio: 2.5"></span>;
            msgText = monriData.message;
            msgImg = "";
        }

        this.wait(1000).then(() => {
            
        let showContentMsg = <div><p className="uk-text-bolder">{msgIcon}<br />{msgText}</p><p dangerouslySetInnerHTML={{__html: msgImg}}></p></div>;
        this.setState({text: showContentMsg});

        
        })

            this.setState({isLoaded: true});
        
      
    }

    componentDidMount() {

        this.progressbar(75);
        
        this.setState({status: false});
       
    }

    componentDidUpdate() {
    
        this.wait(500).then(() => {
            this.startUp()
        });
        
    }




    render() {
        const {text, status, data, isLoaded} = this.state;
       
        return (
            <div className="uk-container uk-container-xsmall uk-light">
            <div className="uk-text-center">
                {text}
            </div>
          </div>
        )
    }
}
export default Finish;