import React from "react";
import sha1 from 'js-sha1';
import UIkit from 'uikit';
import rules from '../lang/rules.json';
import { getLocal } from "../i18n";


export function wait(timeout) {
  return new Promise(resolve => {
      setTimeout(resolve, timeout);
  });
}

export function progressbar(max) {
  var bar = document.getElementById('js-progressbar');  
  if(bar) {
  var progress = UIkit.util.ready(function () {
            
          bar.value = max;
  });
}
}


export function readToken() {
  let token = localStorage.getItem("token");

  if(token) {
    return token
  } else {
    return false;
  }
}



export async function getToken() {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Accept', 'application/json');

  var raw = JSON.stringify({
    "username": process.env.REACT_APP_API_USER,
    "password": process.env.REACT_APP_API_LOGIN
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(process.env.REACT_APP_API_URL + "auth/login_check", requestOptions);
  const json = await response.json()

  if(json.token) {
    localStorage.setItem("token", json.token)
    return true;
   
  } else {
    //console.log('error', json)
    return false;
  }

}

export async function getSeasonInfo() {
  let auth_key = readToken();
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer "+auth_key);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  /*  */
  const response = await fetch(process.env.REACT_APP_API_URL + "season/active/get", requestOptions);
  const json = await response.json()
  return json;

}

export async function getMatchInfo() {

  let auth_key = readToken();
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer "+auth_key);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  /*  */
  const response = await fetch(process.env.REACT_APP_API_URL + "match/active/get", requestOptions);
  const json = await response.json()
  return json;

/*
  let output = false;
  fetch("https://fkz.ekarta.ba/rest/match/get/1", requestOptions)
  .then(response => response.text())
  .then(result => output = result )
  .catch(error => output = error );

  return JSON.parse(output);
  */
}


export async function setUserData(fromData, infoSettings, infoData) {
  let auth_key = readToken();
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer "+auth_key);

  let virtual = false;
  let tickets = {};
  if(fromData.quantity) {
    virtual = true;
    tickets = {
      "virtual_tickets": fromData.quantity,
    }
  } else {
    tickets = {
      "east_stand_tickets": (fromData.quantity_i) ? fromData.quantity_i : 0,
      "west_stand_tickets": (fromData.quantity_w) ? fromData.quantity_w : 0,
      "north_stand_tickets": (fromData.quantity_n) ? fromData.quantity_n : 0,
      "south_stand_tickets": (fromData.quantity_s) ? fromData.quantity_s : 0,
    }
  }

  var raw = JSON.stringify({
    "match_id": infoSettings.id,
    "name": fromData.first_name,
    "lastName": fromData.last_name,
    "email": fromData.email,
    "phone": fromData.phone,
    "tickets": tickets,
    "orderNumber": fromData.order_number,
    "isVirtualTickets": virtual,
    "currency": "BAM",
    "amountPaid": infoData.price
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  if(fromData.seasonTicket == true) {

    const response = await fetch(process.env.REACT_APP_API_URL + "season-ticket-order/create", requestOptions);
    const json = await response.json()
    return json;

  } else {
    const response = await fetch(process.env.REACT_APP_API_URL + "order/create", requestOptions);
    const json = await response.json()
    return json;
  }
  /*  */
 
 
  //console.log(json)
 
}





export function MonriParms(props, url) {
  if (!url) url = window.location.href;
  let name = props.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}





export function Loader() {
  return <span data-uk-spinner="ratio: 0.9"></span>;
}





export async function fetchMonri(data) {

  const { digest, order_number, currency, amount, response_code, url, lang } = data

  let urlApi = process.env.REACT_APP_API_URL + 'order/update/' + order_number;

  if(order_number.slice(0,2) == "ST") {
    urlApi = process.env.REACT_APP_API_URL + 'season-ticket-order/update/' + order_number;
  }

  let auth_key = readToken();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer "+auth_key);


  let rawData = {
    "digest": digest,
    "order_number": order_number,
    "currency": currency,
    "amount": amount,
    "response_code": response_code,
    "url": url,
    "lang": lang,
  };

//console.log(rawData)
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(rawData),
    redirect: 'follow'
  };


  const response = await fetch(urlApi, requestOptions);
  const json = await response.json()
  return json;

}

export function textTicketCard() {
  return (<><div  dangerouslySetInnerHTML={{__html: rules[getLocal()].tickets}}></div></>)
}

export function textVirtualCard() {
  return (<><div  dangerouslySetInnerHTML={{__html: rules[getLocal()].virtual}}></div></>)
}