import React from "react";
import UIkit from 'uikit';
import Moment from 'react-moment';
import { sha512 } from 'js-sha512';
import { progressbar, wait, Loader, getMatchInfo, setUserData, getToken, textTicketCard } from "./components/api";
import _t, { getLocal } from "./i18n";
import { Link } from "react-router-dom";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/" + getLocal() + ".json"));

class PageTickets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      infoData: {
        patternName: "(?!.*[\ \-]{2,})^[a-zA-Z0-9Ā-ſÀ-ÿ\ \-]{2,30}$",
        patternText: "(?!.*[\ ]{2,})^(?!.*[\-\.]{2,})^[a-zA-Z0-9Ā-ſÀ-ÿ\ \.\-]{2,50}$",
        patternEmail: "^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$",
        loading: false,
        price: 0,
        currency: "BAM",
        isLoaded: false,
        limits: { s: 99, w: 99, i: 99, n: 99 }
      },
      formData: {
        step: 1,
        order_number: "UT" + new Date().getTime()
      },
      infoSettings: {},
      hiddenFields: {
        'language': getLocal() != 'bs' ? 'en' : 'hr',
        'currency': 'BAM',
        'transaction_type': 'purchase',
        'authenticity_token': process.env.REACT_APP_MP_AUTH_KEY,
      },
      countriesList: countries.getNames(getLocal()),
      formErrors: {},
      formValid: false,
    }
  }

  handleChange = e => {
    this.formValidation(e.target);

  }

  formValidation = async e => {
    const { name, value, minLength, maxLength, checked, required } = e;
    let element = e;

    if (required == true && value.length == 0) {

      element.classList.add('uk-form-danger');
      element.nextSibling.innerHTML = _t("Polje je obavezno!");

    } else if (!element.checkValidity()) {

      element.classList.add('uk-form-danger');
      element.nextSibling.innerHTML = element.validationMessage;

    } else {
      element.classList.remove('uk-form-danger');
      element.nextSibling.innerHTML = "";

      await this.setState({ formData: { ...this.state.formData, [name]: (checked ? checked : value) } });
    }

    await this.priceCalculator()



  }

  priceCalculator = async e => {
    const { quantity_s, quantity_n, quantity_w, quantity_i } = this.state.formData
    const { southStandTickets, westStandTickets, northStandTickets, eastStandTickets } = this.state.infoSettings
    let priceNew = 0;


    if (quantity_s) { priceNew += quantity_s * southStandTickets.price }
    if (quantity_n) { priceNew += quantity_n * northStandTickets.price }
    if (quantity_w) { priceNew += quantity_w * westStandTickets.price }
    if (quantity_i) { priceNew += quantity_i * eastStandTickets.price }

    this.setState({ infoData: { ...this.state.infoData, price: priceNew } });

  }


  setUpPayment = async => {
    const { infoData, formData, hiddenFields, infoSettings } = this.state;
    let amount_new = (infoData.price * 100).toFixed(0);
    let order_number = formData.order_number;
    let full_name = formData.first_name + " " + formData.last_name;
    hiddenFields.ch_phone = formData.phone;
    hiddenFields.ch_email = formData.email;
    hiddenFields.ch_city = formData.city;
    hiddenFields.ch_country = formData.country;
    hiddenFields.order_info = _t("Ulaznica za") + " " + infoSettings.title;
    hiddenFields.ch_full_name = full_name;
    hiddenFields.amount = amount_new;
    hiddenFields.order_number = order_number
    hiddenFields.currency = infoData.currency;

    hiddenFields.digest = sha512(process.env.REACT_APP_MP_KEY + "" + order_number + "" + amount_new + "" + infoData.currency);

  }


  goToPay = async e => {
    e.preventDefault();

   
    this.setState({ infoData: { ...this.state.infoData, "loading": true } })


    this.priceCalculator();
    this.setUpPayment();
    const { infoData, formData, hiddenFields, infoSettings } = this.state;

    if(infoData.price > 0) {

      document.getElementById("next").setAttribute("disabled", true);
      document.getElementById("next").classList.add("uk-disabled");

    await this.setState({ hiddenFields: hiddenFields });

    let setUser = await setUserData(formData, infoSettings, infoData);

    if (setUser.status != "error") {
      document.getElementById("pay").submit();
      UIkit.modal.alert(setUser)
      progressbar(75);

    } else {
  
      let msg =  _t("Došlo je do greške...")
      if(setUser.errors) {
        msg = msg + " " + _t("Nema više dostupnih ulaznica za tribinu:") + " ";
        if(setUser.errors.south_stand_tickets) {msg = msg + _t("Jug") + ' | '}
        if(setUser.errors.north_stand_tickets) {msg = msg +  _t("Sjever") + ' | '}
        if(setUser.errors.west_stand_tickets) {msg = msg +  _t("Zapad") + ' | '}
        if(setUser.errors.east_stand_tickets) {msg = msg +  _t("Istok") + ' | '}
      }
      UIkit.modal.alert(msg);

      document.getElementById("next").removeAttribute("disabled");
      document.getElementById("next").classList.remove("uk-disabled");
      this.setState({ infoData: { ...this.state.infoData, "loading": false } })
    }


  } else {

    UIkit.modal.alert(_t("Broj odabranih ulaznica ne može biti nula"));
    document.getElementById("next").removeAttribute("disabled");
      document.getElementById("next").classList.remove("uk-disabled");
    this.setState({ infoData: { ...this.state.infoData, "loading": false } })

  }

  }

  startUp = async () => {

    await getToken()
    const data = await getMatchInfo();
    this.setState({ infoSettings: data });
    this.setState({ infoData: { ...this.state.infoData, isLoaded: true } });
    progressbar(50);
  }

  componentDidMount() {
    progressbar(25);

    wait(500).then(() => {
      this.startUp()
    });
  }



  render() {
    const { infoData, formData, infoSettings, hiddenFields, countriesList } = this.state;

    if (infoData.isLoaded == false) {


      return (<div className="uk-container uk-container-xsmall uk-light">
        <div>
          <h1 className="uk-text-center">
            {_t("Učitavanje...")}
          </h1>
        </div>
      </div>)
    } else {

      const { status, message } = this.state.infoSettings;

      if (status == "error") {
        return (<div className="uk-container uk-container-xsmall uk-light">
          <div>
            <h1 className="uk-text-center">
              {/*_t("Došlo je do greške...")*/}
              {_t("Trenutno nema dostupnih utakmica.")}
            </h1>
          </div>
        </div>)


      } else {

        const { title, homeTeam, awayTeam, startDateTime, southStandTickets, westStandTickets, northStandTickets, eastStandTickets } = this.state.infoSettings;

        return (
          <div className="uk-container uk-container-xsmall uk-light">
            <div>
              <h1 className="uk-text-center uk-hidden">{homeTeam} - {awayTeam}</h1>
              <p className="uk-text-center"><strong>{title} - <Moment parse="DD.MM.YYYY. HH:mm:ss" format="DD.MM.YYYY. HH:mm">{startDateTime}</Moment></strong></p>
             <p className="uk-text-center uk-hidden"><small>{_t("Generalni pokrovitelj derbija")}</small><br /><a href="https://telemach.ba/" target="_blank" alt="Telemach"><img src="https://media.fkzeljeznicar.ba/2022/02/telemach.jpg" width="250px" /></a></p>

              <p className="uk-text-center">{_t("Napomena: Nakon kupovine ulaznice, istu je potrebno preuzeti u prostorijama kluba.")}</p>
              <form method="POST" action={process.env.REACT_APP_MP_URL + "v2/form"} id="pay" onSubmit={this.goToPay}>
                <fieldset className="uk-fieldset">
                  <div className="uk-margin uk-child-width-1-1 uk-child-width-1-2@s uk-grid-small uk-child-width-1-1 uk-flex uk-flex-center" data-uk-grid>
                    <div hidden={southStandTickets.count == 0 ? true : false}>
                      <label className="uk-form-label">{/*_t("Tribina Jug") */}{_t("Broj ulaznica")}</label>
                      <input className="uk-input  uk-form-large" name="quantity_s" placeholder={southStandTickets.count == 0 ? "Nedostupno" : "0"} min="0" max={southStandTickets.count > 5 ? infoData.limits.s : southStandTickets.count} disabled={southStandTickets.count == 0 ? true : false} type="number" onChange={this.handleChange} />
                      <div className="error-list"></div>
                    </div>
                    <div hidden={northStandTickets.count == 0 ? true : false}>
                      <label className="uk-form-label">{_t("Tribina Sjever")}</label>
                      <input className="uk-input  uk-form-large" name="quantity_n" placeholder={northStandTickets.count == 0 ? "Nedostupno" : "0"} min="0" max={northStandTickets.count > 5 ? infoData.limits.n : northStandTickets.count} disabled={northStandTickets.count == 0 ? true : false} type="number" onChange={this.handleChange} />
                      <div className="error-list"></div>
                    </div>
                    <div hidden={eastStandTickets.count == 0 ? true : false}>
                      <label className="uk-form-label">{_t("Tribina Istok")}</label>
                      <input className="uk-input  uk-form-large" name="quantity_i" placeholder={eastStandTickets.count == 0 ? "Nedostupno" : "0"} min="0" max={eastStandTickets.count > 5 ? infoData.limits.i : eastStandTickets.count} disabled={eastStandTickets.count == 0 ? true : false} type="number" onChange={this.handleChange} />
                      <div className="error-list"></div>
                    </div>
                    <div hidden={westStandTickets.count == 0 ? true : false}>
                      <label className="uk-form-label">{_t("Tribina Zapad")}</label>
                      <input className="uk-input  uk-form-large" name="quantity_w" placeholder={westStandTickets.count == 0 ? "Nedostupno" : "0"} min="0" max={westStandTickets.count > 5 ? infoData.limits.w : westStandTickets.count} disabled={westStandTickets.count == 0 ? true : false} type="number" onChange={this.handleChange} />
                      <div className="error-list"></div>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="uk-margin-bottom uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand" data-uk-leader>{_t("Ukupno")}</div>
                        <div>{infoData.price.toFixed(2)} KM</div>
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label">{_t("Ime")} *</label>
                      <input className="uk-input" type="text" name="first_name" pattern={infoData.patternName} onChange={this.handleChange} minLength={2} maxLength={30} required />
                      <div className="error-list"></div>

                    </div>
                    <div>
                      <label className="uk-form-label">{_t("Prezime")} *</label>
                      <input className="uk-input" type="text" name="last_name" pattern={infoData.patternName} onChange={this.handleChange} minLength={2} maxLength={30} required />
                      <div className="error-list"></div>

                    </div>
                    <div>
                      <label className="uk-form-label">{_t("E-mail adresa")} *</label>
                      <input className="uk-input" type="email" name="email" pattern={infoData.patternEmail} onChange={this.handleChange} required />
                      <div className="error-list"></div>
                    </div>
                    <div>
                      <label className="uk-form-label">{_t("Telefon")} *</label>
                      <input className="uk-input" type="tel" pattern="[0-9+]{9,20}" name="phone" onChange={this.handleChange} required />
                      <div className="error-list"></div>
                      <small>{_t("Primjer")}: +387XXXXXXXX</small>
                    </div>
                    <div>
                    <label className="uk-form-label">{_t("Grad")} *</label>
                    <input className="uk-input" type="text" name="city" pattern={infoData.patternText} onChange={this.handleChange} minLength={2} maxLength={50} required />
                    <div className="error-list"></div>
                </div>
                    <div>
                    <label className="uk-form-label">{_t("Država")} *</label>
                    <select className="uk-select" name="country" required onChange={this.handleChange}>
                        <option value="" disabled selected hidden>{_t("-- Odaberi --")}</option>
                        {Object.entries(countriesList).sort((a, b) => a[1].localeCompare(b[1])).map(([key, value], index) =>
                        <option value={key} key={index}>{value}</option>)}
                    </select>
                    <div className="error-list"></div>
                </div>


                <div className="uk-width-1-1">
                                            <p><a data-uk-toggle="target: #tickets-modal" className="uk-button uk-button-text">{_t("Opći uslovi kupovine ulaznica")}</a></p>
                                            <label><input className="uk-checkbox" type="checkbox" name="conditions" onChange={this.handleChange} required /> {_t("Saglasan sam sa uslovima kupovine ulaznica")}</label>   
                                            <div className="error-list"></div>
                                            <hr />
                                        </div>
                  </div>

                  {Object.entries(hiddenFields).map(([key, value], index) =>
                    <input className="uk-hidden" type="text" key={index} name={key} value={value} />
                  )}
                </fieldset>
                <div className="uk-margin uk-grid-small uk-child-width-1-2" data-uk-grid>
                  <div><Link to="/" className="uk-button uk-button-default">{_t("Nazad")}</Link></div>
                  <div className="uk-text-right"><button type="submit" id="next" className="uk-button uk-button-default" value="next">{_t("Plaćanje")} {infoData.loading ? <Loader /> : ''}</button></div>
                </div>
              </form>
            </div>

            <div id="tickets-modal" data-uk-modal>
                            <div className="uk-modal-dialog uk-modal-body">
                                <h2 className="uk-modal-title">{_t("KUPOVINA ULAZNICA ZA UTAKMICE FK ŽELJEZNIČAR")}</h2>
                                {textTicketCard()}
                                <hr />
                                <a className="uk-modal-close uk-button">{_t("Zatvori")}</a>
                            </div>
                        </div>
          </div>
        )
      }
    }
  }
}

export default PageTickets;