import React from 'react';

function Footer() {
  
    return (
        <div className="uk-text-center uk-padding uk-margin-top">
          <img src="PayWeb.png" className="uk-margin-small-bottom" /><br />
          <a href="https://www.visa.com/" target="_blank"><img src="cards/visa.png" width="50" /></a>
          <a href="https://www.mastercard.com/" target="_blank"><img src="cards/mastercard.png" width="50" /></a>
          <a href="https://www.maestrocard.com/" target="_blank"><img src="cards/maestro.png" width="50" /></a>
          <a href="https://www.visaeurope.com/making-payments/verified-by-visa/" target="_blank"><img src="cards/verified_by_visa.png"  width="50" /></a>
          <a href="https://www.mastercard.us/en-us/consumers/features-benefits/securecode.html" target="_blank"><img src="cards/mastercard_securecode.png"  width="50" /></a>
        </div>

    );
  }
  
export default Footer;