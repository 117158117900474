import React from 'react';
import _t from '../i18n';

function Navigation() {
  return (
      <div id="offcanvas-overlay" data-uk-offcanvas="overlay: true; mode: push; flip: true">
        <div className="uk-offcanvas-bar app-header uk-background-blue">

          <button className="uk-offcanvas-close" type="button" data-uk-close></button>
          <h3></h3>
          <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical ">
            <li className="uk-nav-header">{_t("Ulaznice")}</li>
            <li data-uk-lightbox><a href="https://tickets.fkzeljeznicar.ba/video1.webm">{_t("Kako kupiti ulaznicu?")}</a></li>
            <li data-uk-lightbox><a href="https://tickets.fkzeljeznicar.ba/video2.webm">{_t("Kako kupiti virtuelnu ulaznicu?")}</a></li>
            <li><a href="https://fkz.ba" target="_blank">{_t("Početna")}</a></li>
            <li className="uk-nav-divider"></li>
            <li className="uk-nav-header">{_t("Adresa")}</li>
            <li>Adresa: Zvornička 27</li>
            <li>71000 Sarajevo</li>
            <li>Bosna i Hercegovina</li>
            <li className="uk-nav-header">{_t("Telefon")}</li>
            <li>+ 387 (0)33 660 133</li>
            <li>+ 387 (0)33 660 134</li>
            <li>(Fax) + 387 (0)33 715-201</li>
            <li className="uk-nav-header">{_t("E-mail")}</li>
            <li>tickets@fkzeljeznicar.ba</li>

          </ul>

        </div>
      </div>
  );
}

export default Navigation;